import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Sidebar from '@/components/Elements/Sidebar.vue';
import Expander from '@/components/Expander/Expander.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import Btn from '@/components/Button/Btn.vue';
import Gallery from '@/components/Gallery/Gallery.vue';
import UpdateProfile from '@/views/Profile/Forms/UpdateProfile.vue';
import SocialShare from '@/components/SocialShare/SocialShare.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let Member = class Member extends Vue {
    constructor() {
        super(...arguments);
        this.shareInfo = {
            url: `${process.env.VUE_APP_URL}member`,
            title: this.$i18n.t('social.share_title'),
            description: 'Become a member of Africa Geographic',
            quote: 'Become a member of Africa Geographic',
            hashtags: `africa,africageographic,club`,
            twitterUser: 'africageo',
        };
        this.coverImage = '';
        this.isBlocked = false;
    }
    async mounted() {
        await this.getMemberBySlug();
        if (this.member) {
            this.setCoverImage();
            this.editSharingInfo();
        }
        else {
            this.getMemberBySlug();
        }
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: '',
            engagementSlug: '',
        });
        this.setBlocked();
    }
    setCoverImage() {
        if (!this.coverImage && this.member.images && this.member.images.length > 0) {
            this.coverImage = this.member.images[0].public_url;
        }
        else {
            this.coverImage = '/img/bg/members-bg.jpg';
        }
    }
    // dynamic user sharing info
    editSharingInfo() {
        this.shareInfo.title = `Africa Geographic - ${this.member.name}`;
    }
    async getMemberBySlug() {
        await this.$store.dispatch('members/getMemberBySlug', this.$route.params.slug);
    }
    async goToConversation() {
        await this.$store.dispatch('conversations/setActiveConversationMember', this.member);
        this.$router.push({ path: `/chat/${this.member.id}` });
    }
    async blockMember() {
        await this.$store.dispatch('members/blockMember', this.member.id);
        this.setBlocked();
    }
    setBlocked() {
        let blockedMembers = this.$store.getters['user/getProfile'].blocked_members.filter((blockedMember) => {
            return blockedMember.blocked_member_id === this.member.id;
        });
        if (blockedMembers.length > 0) {
            this.isBlocked = true;
        }
        else {
            this.isBlocked = false;
        }
    }
};
__decorate([
    Getter('activeMember', { namespace: 'members' })
], Member.prototype, "member", void 0);
Member = __decorate([
    Component({
        components: {
            Btn,
            Sidebar,
            LabelList,
            ProfileCard,
            UpdateProfile,
            Expander,
            Gallery,
            SocialShare,
            CoverImage,
            MarkdownFormatter
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | member',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic member',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/member` }],
            };
        },
    })
], Member);
export default Member;
