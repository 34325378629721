import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// services
import UserService from '@/services/user.ts';
// components
import Btn from '@/components/Button/Btn.vue';
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import LabelSelect from '@/components/FormInputs/LabelSelect/LabelSelect.vue';
import Sidebar from '@/components/Elements/Sidebar.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import Expander from '@/components/Expander/Expander.vue';
import MultiImageUpload from '@/components/MultiImageUpload/MultiImageUpload.vue';
import CheckboxInput from '@/components/FormInputs/CheckboxInput/CheckboxInput.vue';
// forms
import UpdateAvatar from '@/views/Profile/Forms/UpdateAvatar.vue';
let UpdateProfile = class UpdateProfile extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.form = {
            name: '',
            email: '',
            description: '',
            country_code: '',
            city: '',
            phone: '',
            address: '',
            company_name: '',
            company_phone: '',
            company_address: '',
            labels: [],
            email_notification_messages: true,
        };
        this.errors = {
            name: [],
            email: [],
            description: [],
            country: [],
            city: [],
            phone: [],
            address: [],
            company_name: [],
            company_phone: [],
            company_address: [],
            labels: [],
            email_notification_messages: [],
        };
        this.countryOptions = [];
        this.isValid = true;
        this.uploadedImages = [];
        this.isConfirmationModalOpen = false;
        this.confirm_delete = '';
        this.confirm_delete_error = {};
        this.disabled = false;
    }
    async mounted() {
        if (this.countries && this.countries.length <= 0) {
            await this.$store.dispatch('app/getCountries');
        }
        if (this.profile) {
            this.form = await UserService.setUserForm();
            this.loading = false;
        }
        // setup SelectOptions
        this.countryOptions = this.countries?.map((country) => {
            return {
                name: country.name,
                value: country.code,
                selected: false,
            };
        });
    }
    closeUpdate() {
        this.$emit('close');
    }
    // Submit form here
    async submit() {
        try {
            await this.$store.dispatch('user/updateUser', this.form);
            this.$emit('submittedForm');
        }
        catch (e) {
            this.errors = e;
        }
    }
    async showConfirmationModal() {
        this.isConfirmationModalOpen = true;
    }
    async hideConfirmationModal() {
        this.isConfirmationModalOpen = false;
        this.confirm_delete = '';
        this.confirm_delete_error = {};
    }
    async deleteAccount() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('_method', 'delete');
        formData.append('delete', this.confirm_delete);
        try {
            this.loading = true;
            this.disabled = true;
            const response = await UserService.deleteAccount(formData);
            this.confirm_delete = '';
            this.disabled = false;
            this.isConfirmationModalOpen = false;
            this.loading = false;
            // await this.$store.dispatch('notification/showSuccess', response.message)
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            // this.isConfirmationModalOpen = false
            await this.$store.dispatch('notification/showError', e.response.data.message);
            this.confirm_delete_error = e.response.data.data.messages.delete;
        }
    }
    async uploadImages() {
        for (const image in this.uploadedImages) {
            try {
                await this.$store.dispatch('user/uploadImage', this.uploadedImages[image]);
            }
            catch (e) {
                this.errors = e;
            }
        }
        this.uploadedImages = [];
    }
    async deleteExistingImage(value) {
        const imageToDelete = {
            id: value,
            imageable_type: 'user',
            imageable_id: this.profile.id,
        };
        try {
            await this.$store.dispatch('app/deleteImage', imageToDelete);
            await this.$store.dispatch('user/getUserProfile');
        }
        catch (e) {
            // console.log('UpdateProfile.vue - 330 -  e', e)
        }
    }
    // TODO: set featured image - temp set cover image for user
    setAsCoverImage(imagePath) {
        try {
            this.$store.dispatch('user/setCoverImage', imagePath);
        }
        catch (e) {
            // console.log(`UpdateProfile.vue - 330 - error`, e)
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], UpdateProfile.prototype, "profile", void 0);
__decorate([
    Getter('countries', { namespace: 'app' })
], UpdateProfile.prototype, "countries", void 0);
UpdateProfile = __decorate([
    Component({
        components: {
            Btn,
            TextareaInput,
            TextInput,
            SelectInput,
            UpdateAvatar,
            LabelSelect,
            Sidebar,
            ProfileCard,
            Expander,
            MultiImageUpload,
            CheckboxInput,
        },
    })
], UpdateProfile);
export default UpdateProfile;
